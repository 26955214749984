import {inject, Injectable, OnDestroy} from '@angular/core';
import {environment} from '../../../environments/environment';
import {
  isLoginResult,
  LoginExternoResult,
  LoginParams,
  LoginResult,
  LoginSocialParams,
  LoginSocialResult,
  UsuarioInfo,
} from './login/login.model';
import {Observable, Subject, tap} from 'rxjs';
import {HttpRequestService} from '../../core/services/http-request/http-request.service';
import {Response, ResponseBase} from '../../core/models/response.model';
import {LocalStorageService} from '../../core/services/local-storage/local-storage.service';
import {Router} from '@angular/router';
import {RecuperarSenhaParams, RecuperarSenhaResult,} from './recuperar-senha/recuperar-senha.model';
import {AlterarSenhaParams, AlterarSenhaResult,} from './alterar-senha/alterar-senha.model';
import {
  AtivacaoContaModel,
  CriarContaParams,
  CriarContaSocialParams,
  CriarContaSocialResult,
} from './criar-conta/criar-conta.model';
import {DateExtensions} from '../../shared/extensions/date.extensions';
import {AtivarContaParams} from './ativar-conta/ativar-conta.model';
import {RenovarTokenModel, SolicitarTrocaSenhaModel, SolicitarTrocaSenhaRetornoModel,} from './autenticacao.model';
import {AtivarContaPorLinkParams} from './ativar-conta-por-link/ativar-conta-por-link.model';
import {RumService} from '../../core/services/rum/rum.service';

@Injectable({
  providedIn: 'root',
})
export class AutenticacaoService implements OnDestroy {
  public informacaoUsuarioUpdated$ = new Subject<UsuarioInfo>();
  private _router = inject(Router);
  private _storage = inject(LocalStorageService);
  private _httpRequest = inject(HttpRequestService);
  private _rum = inject(RumService);

  ngOnDestroy(): void {
    this.informacaoUsuarioUpdated$.complete();
  }

  /**
   * Requisição HTTP para realizar o login do usuário
   */
  login(params: LoginParams): Observable<Response<LoginResult>> {
    if (params.aplicacaoId == null) {
      params.aplicacaoId = environment.aplicacao.id;
    }
    return this._httpRequest
      .post<Response<LoginResult>>('/v1/login', params)
      .pipe(
        tap((p) => {
          this.salvarInfoUsuarioLogado(p.data);
        })
      );
  }

  /**
   * Requisição HTTP para realizar o login do usuário a partir de uma rede social
   */
  loginSocial(
    params: LoginSocialParams
  ): Observable<
    Response<LoginResult | LoginSocialResult | LoginExternoResult>
  > {
    if (params.aplicacaoId == null) {
      params.aplicacaoId = environment.aplicacao.id;
    }
    return this._httpRequest
      .post<Response<LoginResult | LoginSocialResult | LoginExternoResult>>(
        '/v1/login-social',
        params
      )
      .pipe(
        tap((p) => {
          if (isLoginResult(p.data)) {
            this.salvarInfoUsuarioLogado(p.data);
          }
        })
      );
  }

  /**
   * Requisição HTTP para realizar o login do usuário
   */
  loginExterno(params: LoginParams): Observable<Response<LoginExternoResult>> {
    params.loginExterno = true;
    if (params.aplicacaoId == null) {
      params.aplicacaoId = environment.aplicacao.id;
    }
    return this._httpRequest.post<Response<LoginExternoResult>>(
      '/v1/login',
      params
    );
  }

  /**
   * Requisição HTTP para criar conta do usuário
   */
  criarConta(
    params: CriarContaParams
  ): Observable<Response<AtivacaoContaModel>> {
    params.dataNascimento = DateExtensions.onlyDateForServer(
      params.dataNascimento
    )!;
    params.celular =
      params.celular == '' || params.celular == null
        ? null
        : `+55${params.celular}`;

    return this._httpRequest.post<Response<AtivacaoContaModel>>(
      '/v1/criar-conta',
      params
    );
  }

  /**
   * Requisição HTTP para criar conta do usuário a partir de uma rede social
   */
  criarContaSocial(
    params: CriarContaSocialParams
  ): Observable<Response<CriarContaSocialResult>> {
    return this._httpRequest.post<Response<CriarContaSocialResult>>(
      '/v1/criar-conta-social',
      params
    );
  }

  /**
   * Requisição HTTP para ativar a conta do usuário
   */
  ativarConta(params: AtivarContaParams): Observable<ResponseBase> {
    return this._httpRequest.put<ResponseBase>('/v1/ativar-conta', params);
  }

  ativarContaPorLink(
    params: AtivarContaPorLinkParams
  ): Observable<ResponseBase> {
    return this._httpRequest.put<ResponseBase>(
      '/v1/ativar-conta-por-link',
      params
    );
  }

  /**
   * Requisição HTTP para solicitar token para alteração de senha do usuário
   */
  recuperarSenha(
    params: RecuperarSenhaParams
  ): Observable<Response<RecuperarSenhaResult>> {
    return this._httpRequest.post<Response<RecuperarSenhaResult>>(
      '/v1/recuperar-senha',
      params
    );
  }

  /**
   * * Requisição HTTP para solicitar token para alteração de senha do usuário informando a senha atual
   */
  solicitarTrocaSenha(params: SolicitarTrocaSenhaModel) {
    return this._httpRequest.post<Response<SolicitarTrocaSenhaRetornoModel>>(
      '/v1/usuario/alterar-senha',
      params
    );
  }

  /**
   * Requisição HTTP para alterar a senha do usuário
   */
  alterarSenha(
    params: AlterarSenhaParams
  ): Observable<Response<AlterarSenhaResult>> {
    return this._httpRequest.put<Response<AlterarSenhaResult>>(
      '/v1/usuario/alterar-senha',
      params
    );
  }

  renovarToken() {
    const lDadosUsuarioLogado = this.obterDadosUsuarioLogado();
    let params: RenovarTokenModel = {
      usuarioId: lDadosUsuarioLogado!.usuarioInfo.id,
      refreshToken: lDadosUsuarioLogado!.refreshToken,
    };

    return this._httpRequest
      .put<Response<LoginResult>>('/v1/renovar-token', params)
      .pipe(
        tap((p) => {
          this.salvarInfoUsuarioLogado(p.data);
        })
      );
  }

  logout(desativarRedirect?: boolean) {
    this.limparInfoUsuarioLogado(desativarRedirect);
    this._rum.stopSession();
  }

  /**
   * Recupera os dados do usuário logado.
   */
  obterDadosUsuarioLogado(): LoginResult | null {
    return this._storage.get<LoginResult>(environment.keys.userInfo);
  }

  /**
   * Atualizar informações do usuário logado.
   */
  atualizarDadosUsuarioLogado(info: { avatar?: string, nomeCompleto?: string, apelido?: string }) {
    let lInfoUsuarioLogado = this._storage.get<LoginResult>(environment.keys.userInfo);

    if (info.avatar)
      lInfoUsuarioLogado!.usuarioInfo.avatar = info.avatar;
    if (info.nomeCompleto)
      lInfoUsuarioLogado!.usuarioInfo.nomeCompleto = info.nomeCompleto;
    if (info.apelido)
      lInfoUsuarioLogado!.usuarioInfo.apelido = info.apelido;

    this._storage.set(environment.keys.userInfo, lInfoUsuarioLogado);
    this.informacaoUsuarioUpdated$.next(lInfoUsuarioLogado!.usuarioInfo);

    this._rum.setUserSession(
      lInfoUsuarioLogado!.usuarioInfo.id,
      lInfoUsuarioLogado!.usuarioInfo.nomeCompleto,
      lInfoUsuarioLogado!.usuarioInfo.email
    );
  }

  /**
   * Faz o logout do usuário
   */
  private limparInfoUsuarioLogado(desativarRedirect?: boolean) {
    this._storage.remove(environment.keys.userInfo);
    if (!desativarRedirect) {
      this._router.navigate(['autenticacao/login'], {
        queryParamsHandling: 'preserve',
      });
    }
  }

  /**
   * Salva as informações do usuário autenticado.
   */
  private salvarInfoUsuarioLogado(loginResult: LoginResult) {
    const infoUsuario: LoginResult = {
      refreshToken: loginResult.refreshToken,
      token: loginResult.token,
      usuarioInfo: {
        apelido: loginResult.usuarioInfo.apelido,
        email: loginResult.usuarioInfo.email,
        id: loginResult.usuarioInfo.id,
        nomeCompleto: loginResult.usuarioInfo.nomeCompleto,
        avatar: loginResult.usuarioInfo.avatar,
      },
    };

    this._storage.set(environment.keys.userInfo, infoUsuario);
    this.informacaoUsuarioUpdated$.next(infoUsuario.usuarioInfo);
    
    this._rum.setUserSession(
      loginResult.usuarioInfo.id,
      loginResult.usuarioInfo.nomeCompleto,
      loginResult.usuarioInfo.email
    );
  }
}
